import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Paths } from 'app/navigate';
import { CTRContext } from './CTRContext';
import { useAppContext } from 'app/AppContext';
import { CTRTasks } from './CTRTasks';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';

// CTR = Custom Task Readiness
export const CTRPage = () => {
  const appContext = useAppContext();
  const ctrContext = CTRContext(appContext);
  const canEdit = appContext.appState.getIn(['user', 'role']) === 'manager';
  const { state, controller } = ctrContext;
  const isLoading = state.get('isLoading');
  const ctrTasks = state.get('ctrTasks');
  const addTask = task => {
    controller.addCTRTask(task);
  };

  return (
    <div className="ml-0 pl-4 mt-4">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <Breadcrumb tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.UpcomingTasks }}>
            Upcoming
          </Breadcrumb.Item>
          <Breadcrumb.Item active tag="span">
            Preceding Tasks
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={canEdit ? 'mb-4' : 'mb-5'}>
        <span className="h4 mb-0 font-weight-bold d-block">Preceding Tasks</span>
        <span className="font-size-14 d-block pr-1">
          {canEdit
            ? 'Select the tasks you want to be aware of their status so you know when to start working on your assigned tasks.'
            : 'Only the Account Manager can set the Preceding Tasks.'}
        </span>
      </div>
      <Row className="mt-2 mr-0">
        <Col sm={12} md={10} lg={8} style={{ maxWidth: '575px' }}>
          {canEdit && <SearchGTL selectedTasks={ctrTasks} addTask={addTask} state={state} />}
          <CTRTasks context={ctrContext} canEdit={canEdit} />
        </Col>
      </Row>
      <EmptyStateCenterContainer top="0px">
        <EmptyState
          visible={!isLoading && ctrTasks.size === 0}
          icon="wrench"
          title="No Preceding Tasks Added"
          body="When a Preceding Task is added, it will appear here."
        />
      </EmptyStateCenterContainer>
    </div>
  );
};
