export const Home = '/';
export const DashboardV2 = `${Home}dashboard`;
export const RequestAndOverdueDetails = `${DashboardV2}/update-request-and-overdue`;

export const WorkloadPlanning = `${DashboardV2}/workload-planning`;

export const ToDo = `${Home}to-do`;
export const UpcomingTasks = `${Home}upcoming`;
export const PrecedingTasks = `${UpcomingTasks}/preceding`;

export const Job = `${Home}job`;
export const JobDetails = `${Job}/:jobId([a-f\\d]{24})`;

export const Task = `${Home}task`;
export const TaskOpen = `${Task}/open`;
export const TaskCompleted = `${Task}/completed`;
export const TaskStatus = `${Task}/:status(open|completed|in-progress|not-started|not-ready-to-start|checked-in)`;
export const TaskDetails = `${TaskStatus}/job/:jobId([a-f\\d]{24})/:taskId(\\d+)`;

export const Community = `${Home}community`;
export const CommunityDetails = `${Home}community/:communityId([a-f\\d]{24})`;
export const CommunityIntake = `${CommunityDetails}/intake`;

export const Settings = `${Home}settings`;
export const CustomRoleDetails = `${Settings}/custom-role/:customRoleId([a-f\\d]{24})`;
export const Installer = `${Home}installer`;
export const MyProfile = `${Home}my-profile`;

export const Users = `${Home}users`;
export const UserProfile = `${Users}/profile/:userId([a-f\\d]{24})`;

export const NotPermission = `${Home}not-permission`;
