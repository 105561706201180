import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Datepicker, Impact, PrimaryButton, UserNameLabel } from '@tradetrax/web-common';
import {
  mongoToTrx,
  mongoToTextYearComparison,
  datepickerFormat,
  plural,
  hasPermissionForAssignedTask,
  isUserInactive,
  getMinEndDate,
  isDifferentYear,
  isURDateOverdue,
} from '@tradetrax/web-common/lib/utils';
import { useAppContext } from 'app/AppContext';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { NEW_SYSTEM_START_DATE } from './TaskDetailsContent';

export function TaskExpFinishRequest({ task, controller, handleChangeAssignee }) {
  const { account, hasPermission, appState } = useAppContext();
  const assignees = account.get('assigneesActive');
  const accountUsers = account.get('assignees');
  const changeRequest = task.get('changeRequest');
  const isJobDelayed = (changeRequest.get('jobDelay') || 0) > 0;
  const isStartUR = changeRequest.get('type') === NEW_SYSTEM_START_DATE;
  const jobImpactText =
    changeRequest.get('jobDelay') === 0
      ? 'Cycle Time Impact'
      : isJobDelayed
      ? 'Increase Cycle Time'
      : 'Reduce Cycle Time';
  const assigneeId = task.getIn(['assigneeAccount', 'installerId']);
  const selected = accountUsers.find(i => i.get('userId') === assigneeId);
  const onChange = ([assignee]) => handleChangeAssignee(assignee);
  const userId = appState.getIn(['user', '_id']);
  const canEditInstaller = hasPermissionForAssignedTask('task_update_installer', hasPermission, task, userId);
  const canEditUpdateRequest = hasPermissionForAssignedTask('manage_update_request', hasPermission, task, userId);
  const hourRange = changeRequest.get('hourRange');
  const [isChecked, setIsChecked] = React.useState(task.getIn(['changeRequest', 'reasons'])?.includes('weather'));

  const sendToBuilder = () => {
    if (isStartUR) return controller.sendProposeStartDateToBuilder(isChecked);
    else return controller.sendExpFinishDateRequestToBuilder(isChecked);
  };

  return (
    <div className="d-flex flex-column mt-4 pt-3" style={{ width: '32rem' }}>
      <Form.Label>Lead Installer Assignee</Form.Label>
      {!canEditInstaller && (
        <UserNameLabel
          isInactive={isUserInactive(assigneeId, accountUsers)}
          className={cn('not-assignee font-size-16', {
            'text-gray-200': !selected?.get('name'),
          })}
        >
          {selected?.get('name') || 'Not Assigned'}
        </UserNameLabel>
      )}
      {canEditInstaller && (
        <div className="field-editable" style={{ minWidth: '13rem' }}>
          <Typeahead
            id="assigneeInstaller"
            bsSize="lg"
            clearButton
            placeholder="Choose Installer"
            selected={selected ? [selected] : []}
            labelKey={option => option.get('name')}
            options={assignees.toArray()}
            onChange={onChange}
          >
            {!selected && <FontAwesomeIcon icon="chevron-down" />}
          </Typeahead>
        </div>
      )}
      <div className="d-flex flex-row mt-3">
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Trade Super</small>
          <UserNameLabel
            className={cn('not-assignee font-size-16', {
              'text-gray-200': !task.getIn(['userSuper', 'name']),
            })}
            isInactive={isUserInactive(task.getIn(['userSuper', '_id']), accountUsers)}
          >
            {task.getIn(['userSuper', 'name']) || 'Not Assigned'}
          </UserNameLabel>
        </div>
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Trade Scheduler</small>
          <UserNameLabel
            className={cn('not-assignee font-size-16', {
              'text-gray-200': !task.getIn(['userScheduler', 'name']),
            })}
            isInactive={isUserInactive(task.getIn(['userScheduler', '_id']), accountUsers)}
          >
            {task.getIn(['userScheduler', 'name']) || 'Not Assigned'}
          </UserNameLabel>
        </div>
      </div>
      <span className="h5 mb-0 font-weight-bold mt-4 pt-3">Update Request</span>
      <Impact>
        <ProposedDate task={task} canEdit={canEditUpdateRequest} controller={controller} isStartUR={isStartUR} />
        <ExpectedDate task={task} isStartUR={isStartUR} />
        {hourRange ? (
          <li>
            <label>Hour Range</label>
            <span className="text-secondary">{hourRange}</span>
          </li>
        ) : null}
        <li
          className={cn({
            'text-danger': isJobDelayed,
            'text-success': !isJobDelayed && changeRequest.get('jobDelay') !== 0,
          })}
        >
          <label>{jobImpactText}</label>
          <span>{plural.day(Math.abs(changeRequest.get('jobDelay')))}</span>
        </li>
        <li>
          <span className="font-weight-bold">Due to Weather</span>
          <input
            style={{ width: '18px', height: '18px' }}
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            disabled={!canEditUpdateRequest}
          />
        </li>
      </Impact>
      <div className="small d-flex flex-row">
        <span className="text-muted mr-1">Update request by: </span>
        <UserNameLabel
          className="not-assignee font-size-16"
          isInactive={isUserInactive(changeRequest.get('createdById'), accountUsers)}
        >
          {changeRequest.get('createdByName')}
        </UserNameLabel>
        <span className="text-muted mr-3 ml-auto"> {moment.utc(changeRequest.get('createdOn')).fromNow()}</span>
      </div>
      {isJobDelayed && (
        <span className="font-size-12 mt-3">
          If approved, this Update Request <strong>will delay the finish date for the Job</strong>. Please double-check
          before sending it to the Builder for approval.
        </span>
      )}
      {canEditUpdateRequest && (
        <div className="mt-4 d-flex flex-row">
          <PrimaryButton variant="secondary" className="mr-3" onClick={controller.cancelExpFinishDateRequest}>
            Decline
          </PrimaryButton>
          <PrimaryButton className="ml-3" onClick={sendToBuilder}>
            Send to Builder
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

function ProposedDate({ task, canEdit, controller, isStartUR }) {
  const changeRequest = task.get('changeRequest');
  const requestProposedDate = isStartUR
    ? changeRequest.get('proposedStartDate')
    : changeRequest.get('proposedFinishDate');
  const proposedDate = datepickerFormat(mongoToTrx(requestProposedDate));
  const taskExpDate = datepickerFormat(mongoToTrx(task.get(isStartUR ? 'startDate' : 'expectedFinishDate')));
  const shouldDisplayYear = isDifferentYear(proposedDate);
  const minEndDate = isStartUR ? null : getMinEndDate(task.get('startDate'));
  const lateDate = isStartUR ? task.get('lateStartDate') : task.get('lateEndDate');
  const criticalDate = new Date(lateDate || undefined);
  const noEditDate = isStartUR ? requestProposedDate : changeRequest.get('proposedFinishDate');
  const isTaskDelayed = isStartUR ? (task.get('daysBehind') || 0) > 0 : false;
  const showDatePiker = canEdit && !isStartUR;

  const getDayClassName = date => criticalProposedDateClass(date, proposedDate, taskExpDate, criticalDate);
  const getCriticalDay = (day, date) => CriticalDay(day, date, criticalDate, proposedDate);

  const onChange = date => {
    if (!isStartUR) controller.updateExpFinishDateRequest(date);
  };

  return (
    <li>
      <label>{`Proposed ${isStartUR ? 'Start' : 'Finish'}`}</label>
      {showDatePiker ? (
        <div style={{ width: `${shouldDisplayYear ? '130' : '120'}px` }}>
          <Datepicker
            name="proposedDate"
            selected={proposedDate}
            excludeDates={[taskExpDate]}
            minDate={minEndDate}
            dateFormat={`MMM dd${shouldDisplayYear ? ', yyyy' : ''}`}
            onChange={onChange}
            placeholderText=""
            dayClassName={getDayClassName}
            renderDayContents={getCriticalDay}
            calendarClassName="react-datepicker__critical"
          >
            <WarningCriticalMsg showMsg={showWarningCriticalMsg(proposedDate, criticalDate)} />
          </Datepicker>
        </div>
      ) : (
        <span className={cn({ 'text-danger': isTaskDelayed })}>{mongoToTextYearComparison(noEditDate)}</span>
      )}
    </li>
  );
}

function ExpectedDate({ task, isStartUR }) {
  const expectedDate = isStartUR ? task.get('startDate') : task.get('expectedFinishDate');
  const isDateOverdue = isURDateOverdue(task, expectedDate);

  return (
    <li
      className={cn({
        'text-danger': isDateOverdue,
      })}
    >
      <label>{`${isDateOverdue ? 'Missed' : 'Expected'} ${isStartUR ? 'Start' : 'Finish'}`}</label>
      <span>{mongoToTextYearComparison(expectedDate)}</span>
    </li>
  );
}
