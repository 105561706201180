import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { IconButton } from '@tradetrax/web-common/lib/Button';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { getAssigneeProps } from '@tradetrax/web-common/lib/Dashboard/Upcoming/UpcomingSection';
import { UpcomingContext } from './UpcomingContext';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { useAppContext } from 'app/AppContext';
import { UpcomingCard } from './UpcomingCard';
import { LoadingCards } from './LoadingCards';
import { CalendarView } from '@tradetrax/web-common/lib/CalendarView/index';
import navigate from 'app/navigate';
import { Paths } from 'app/navigate';
import { subsService } from 'services';
import { BulkActionsContext, BulkContext } from '@tradetrax/web-common/lib/BulkActions/BulkActions.context';
import * as bulkActions from './Upcoming.bulk.actions';
import { BulkHeader } from './Bulk.bar';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export const UpcomingPage = () => {
  const appContext = useAppContext();
  const communities = useCommunities(subsService);
  const taskTypes = useTaskTypes(subsService);
  const upcomingContext = UpcomingContext(appContext);
  const bulkContext = BulkActionsContext(bulkActions, subsService, upcomingContext);
  const { controller, state, containerRef, filterContext } = upcomingContext;
  const { tasks, isLoading, totalCount, groups, view } = state.toObject();
  const { isFiltering } = filterContext;
  const title = isFiltering ? 'No Matches Found' : 'No Upcoming Tasks';
  const body = isFiltering
    ? "When there's a Task that meets the filter, it will appear here."
    : 'When a future Task is assigned to you, it will appear here.';
  const icon = isFiltering ? 'wrench' : 'circle-check';
  const showCalendar = view === CALENDAR_VIEW;
  const { filterSets } = filterContext;
  const isManaging = bulkContext.state.get('isManaging');
  const lookups = { communities, taskTypes };
  const filter = filterContext.filterState.get('values');

  const { getPermission } = appContext;
  const updateRequestPermission = getPermission('manage_update_request');
  const updateInstallerPermission = getPermission('task_update_installer');
  const cannotManageBulk = tasks.size === 0 || (updateRequestPermission === 'no' && updateInstallerPermission === 'no');

  return (
    <BulkContext.Provider value={bulkContext}>
      <div ref={containerRef}>
        <div className="d-flex flex-column mt-4  mx-4">
          <span className="h4 mb-2 font-weight-bold d-block">Upcoming</span>
          <div className="d-flex flex-row align-items-center justify-content-between mb-4">
            <span className="d-block font-size-14">Move to In Progress or Update Start Date.</span>
            <Link to={Paths.PrecedingTasks} className="mb-n1 mr-2 ml-auto">
              <FontAwesomeIcon icon="wrench" className="mr-2" />
              Set Up Preceding Tasks
            </Link>
          </div>
          <div className={cn({ 'mt-5': filterSets.size < 1 })}>
            <FilterSets.Panel filterContext={filterContext} visible disabled={isManaging} isUpcomingView>
              <div className="d-flex justify-content-end">
                <ManageTasksButton bulkContext={bulkContext} hidden={showCalendar || cannotManageBulk} />
                <IconButton
                  disabled={isManaging}
                  icon={showCalendar ? 'list' : 'calendar-days'}
                  onClick={() => controller.toggleView()}
                  className="mr-3 active-on-hover"
                />
                <FilterImplementation
                  filterContext={filterContext}
                  lookups={lookups}
                  title="Upcoming Tasks Filters"
                  iconCustomClass={{ 'ml-auto': true }}
                  disabled={isManaging}
                >
                  <FilterSets.Section filterContext={filterContext} />
                </FilterImplementation>
              </div>
            </FilterSets.Panel>
          </div>
        </div>
        <BulkHeader bulkContext={bulkContext} appContext={appContext} tasks={tasks} />

        <Container style={{ flex: '1 1 auto' }} fluid={showCalendar ? true : 'lg'} className=" pl-4 mr-auto ml-0">
          {showCalendar ? (
            <div className="pt-2">
              <CalendarView
                filter={filter}
                appContext={appContext}
                calendarService={subsService}
                navigate={navigate}
                isUpcoming
              />
            </div>
          ) : (
            <>
              <EmptyStateCenterContainer>
                <EmptyState pt={5} visible={!isLoading && tasks.size === 0} icon={icon} title={title} body={body} />
              </EmptyStateCenterContainer>
              <Row className="pt-2">
                {!isLoading && tasks.size > 0 && (
                  <Col sm={12}>
                    {groups
                      .map((tasks, key) => (
                        <UpcomingDay
                          key={key}
                          date={key}
                          tasks={tasks}
                          controller={controller}
                          bulkContext={bulkContext}
                          assigneeProps={getAssigneeProps(appContext, controller)}
                        />
                      ))
                      .valueSeq()}
                  </Col>
                )}
                <Col sm={12}>
                  {(isLoading || tasks.size < totalCount) && <LoadingCards howMany={isLoading ? 6 : 1} />}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </BulkContext.Provider>
  );
};

const ManageTasksButton = ({ bulkContext, hidden }) => {
  const { state, controller } = bulkContext;
  const isManaging = state.get('isManaging');

  if (hidden) return null;
  return (
    <button onClick={e => controller.toggleManaging()} className="btn btn-link pt-0">
      {isManaging ? 'Close ' : ''}Manage Tasks
    </button>
  );
};

const isUpcomingTask = task => {
  const { status, checkedIn } = task.toObject();
  return status === 'not-started' || checkedIn !== null || status === 'not-ready-to-start';
};

const DAYS = ['Today', 'Tomorrow'];

const taskId = task => task.getIn(['job', 'id']) + '_' + task.get('id');

const UpcomingDay = ({ controller, tasks, date, assigneeProps, bulkContext }) => {
  const [day, dayText] = React.useMemo(() => {
    const mDate = moment(date, 'YYYY-MM-DD');
    const diffDays = mDate.diff(moment().startOf('date'), 'days');
    return [mDate.format('MMM D'), DAYS[diffDays]];
  }, [date]);
  const showUpcomingDay = tasks.filter(task => isUpcomingTask(task)).size;
  const isManaging = bulkContext.state.get('isManaging');
  const toggleCheckbox = task => e => {
    const checked = e.target.checked;
    bulkContext.controller.toggleTask(taskId(task), checked);
  };
  const selectedTasks = bulkContext.state.get('selectedTasks');
  const isChecked = task => selectedTasks.indexOf(taskId(task)) >= 0;

  const CardRow = ({ task }) => (
    <div className="d-flex">
      {isManaging ? (
        <input
          className="d-flex m-2 mt-n4 cursor-pointer"
          checked={isChecked(task)}
          type="checkbox"
          onChange={toggleCheckbox(task)}
        />
      ) : null}
      <UpcomingCard
        task={task}
        controller={controller}
        showPrecedingTasks={controller.showPrecedingTasks}
        assigneeProps={assigneeProps(task)}
      />
    </div>
  );

  return (
    <div className={cn('mb-4', { 'd-none': !showUpcomingDay })}>
      <div className="mb-3 pb-1">
        <span className="h5 font-weight-bold">{day}</span>
        {dayText && <span className="font-size-14 font-weight-bold ml-2">{dayText}</span>}
      </div>
      {tasks.map(task => (
        <CardRow task={task} key={task.get('key')} />
      ))}
    </div>
  );
};
