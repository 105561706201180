import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { UpcomingPage } from './UpcomingPage';
import { CTRPage } from './Preceding/CTRPage';
import { NotFound } from '@tradetrax/web-common';

export const UpcomingRoutes = () => {
  return (
    <Switch>
      <Route path={Paths.UpcomingTasks} component={UpcomingPage} exact />
      <Route path={Paths.PrecedingTasks} component={CTRPage} exact />
      <Route component={NotFound} />
    </Switch>
  );
};
