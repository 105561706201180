import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import navigate, { Paths } from 'app/navigate';
import { Breadcrumb } from 'react-bootstrap';
import { PrimaryButton, ToggleButtons, ToggleButton, colors } from '@tradetrax/web-common';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { IntakeController, SCHEDULER_TAB, SUPER_TAB, ALL_TASKS } from './IntakeController';
import { IntakeTasks } from './IntakeTasks';
import { useAppContext } from 'app/AppContext';

export function Intake({ match }) {
  const { communityId } = match.params;
  const intakeContext = IntakeController(communityId);
  const { state, controller, filterContext } = intakeContext;
  const { community, tab, tasksBy } = state.toObject();
  const appContext = useAppContext();
  const { account } = appContext;
  const assignees = account.get('regularUsersActive');
  const lookups = { assignees };
  const isFilterDisabled =
    state.getIn(['selectedTasks', tab, 'taskIds']).size > 0 || state.getIn(['selectedTasks', tab, 'selectAll']);
  const isAssignButtonDisabled =
    !state.getIn(['selectedTasks', tab, 'taskIds']).size && !state.getIn(['selectedTasks', tab, 'selectAll']); // if taskBy === ALL_TASKS ? tasksIds : taskNames

  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
          Communities
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: navigate.to.CommunityDetails({ communityId }, false) }}>
          {community.get('name')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Intake</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mx-4 d-flex flex-row align-items-center justify-content-between">
        <span className="h4 pt-0 pt-md-3 mt-1 mb-0 font-weight-bold">{`Intake for ${community.get('name')}`}</span>
        <div className="d-flex">
          <PrimaryButton
            disabled={isAssignButtonDisabled}
            onClick={() => controller.openAssignTasksModal(tab, tasksBy)}
            className="mr-4 text-nowrap"
          >
            Assign Tasks
          </PrimaryButton>
          <FilterImplementation filterContext={filterContext} lookups={lookups} disabled={isFilterDisabled} />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between mt-4 mr-4">
        <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
          <ToggleButton value={SUPER_TAB}>Super</ToggleButton>
          <ToggleButton value={SCHEDULER_TAB}>Scheduler</ToggleButton>
        </ToggleButtons>
        <div className="d-flex flex-row align-items-center mr-2">
          <span className="mr-3">Show Task by:</span>
          <Tag>All Tasks</Tag>
        </div>
      </div>
      {tasksBy === ALL_TASKS && <IntakeTasks tab={tab} communityId={communityId} {...intakeContext} />}
    </>
  );
}

const Tag = styled.span`
  background: ${colors.gray100};
  border: none;
  color: ${colors.gray400};
  padding: 3px 15px;
  border-radius: 5px;
`;
