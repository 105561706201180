import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { Breadcrumb, Col } from 'react-bootstrap';
import { useAppContext } from 'app/AppContext';
import { subsService } from 'services';

import { TaskDetailsController } from './TaskDetailsController';
import { TaskDetailsContent } from './WorkFlows/TaskDetailsContent';
import navigate, { Paths } from 'app/navigate';
import { STATUS_MAP, ChipStatusDropdown, DaysBehind, TaskLoading, TaskFooter } from '@tradetrax/web-common';
import { TaskHistory } from '@tradetrax/web-common/lib/TaskHistory';
import { hasPermissionForAssignedTask, NOT_STARTED, IN_PROGRESS, COMPLETED } from '@tradetrax/web-common/lib/utils';
import { emptyTask } from 'app/entities';
import { CriticalPathLabel } from '@tradetrax/web-common/lib/CriticalPath';

export function TaskDetails({ match, location }) {
  const { jobId, taskId } = match.params;
  const appContext = useAppContext();
  const { state, controller } = TaskDetailsController({ taskId, jobId, appContext });
  const { account, appState, hasPermission, modal } = appContext;
  const { task, job } = state.toObject();
  const accountId = account.get('_id');
  const user = appState.get('user');

  const tasks = state.getIn(['job', 'tasks']);
  const isTaskMissing = tasks?.size && !tasks.find(t => t.get('id') === task?.get('id'));
  if (!state.get('hasPermission') || isTaskMissing || state.getIn(['job', 'released']) === false)
    return <Redirect to={Paths.NotPermission} />;
  if (!task || task === emptyTask) return <TaskLoading />;

  const taskStatus = task.get('status') === 'completed' ? 'completed' : 'open';
  const backPath = getBackPath(taskStatus);
  const isAccountAssigned = task.getIn(['assigneeAccount', 'companyId']) === accountId;
  const userId = appState.getIn(['user', '_id']);
  const canEditStatus = hasPermissionForAssignedTask('task_update_status', hasPermission, task, userId);
  const isStatusDisabled = !(isAccountAssigned && canEditStatus);
  const accountUsers = account.get('assignees');

  return (
    <div className="mt-4" style={{ overflowY: 'none' }}>
      <div className="mx-4">
        <Col xl={6} lg={8} md={12} sm={12}>
          <TaskHistoryContainer>
            <Breadcrumb tag="nav">
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: backPath }}>
                Tasks
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{task.get('name')}</Breadcrumb.Item>
            </Breadcrumb>
            {isAccountAssigned && <div className="d-lg-none d-xl-none pt-5 pb-3"></div>}
            {isAccountAssigned && (
              <TaskHistory
                jobId={jobId}
                taskId={taskId}
                historyService={subsService}
                account={account}
                user={user}
                showActions={false}
                accountUsers={accountUsers}
                modal={modal}
              />
            )}
          </TaskHistoryContainer>

          <Header
            task={task}
            handleChangeStatus={controller.updateStatus}
            isAccountAssigned={isAccountAssigned}
            isDisabled={isStatusDisabled}
          />
          <SubHeader job={job} />
          <TaskDetailsContent
            task={task}
            handleChangeAssignee={controller.assignTask}
            controller={controller}
            isSelfAssigned={isAccountAssigned}
          />
        </Col>
      </div>
      <TaskFooter
        task={task}
        service={subsService}
        controller={controller}
        appContext={appContext}
        className="mt-4"
        location={location}
      />
    </div>
  );
}

const Header = ({ task, handleChangeStatus, isDisabled }) => {
  const isCheckedIn = !!task.get('checkedIn');
  const taskStatus = task.get('status');
  const showDaysAhead = false;
  const isCritical = task.get('isCritical');
  return (
    <>
      <div className="d-flex flex-row align-items-center mr-auto">
        <span className="h4 mb-0 font-weight-bold">{task.get('name')}</span>
        <ChipStatusDropdown
          {...STATUS_MAP[taskStatus]}
          className="mx-2"
          notStarted={taskStatus !== NOT_STARTED || isCheckedIn}
          progress={taskStatus !== IN_PROGRESS}
          completed={taskStatus !== COMPLETED}
          onSelect={status => handleChangeStatus({ status })}
          disabled={isDisabled}
        />
        {showDaysAhead && <DaysBehind daysBehind={task.get('daysBehind')} />}
      </div>
      {isCritical && <CriticalPathLabel />}
    </>
  );
};

const SubHeader = ({ job }) => {
  const isNotSet = !job.get('lotNumber');
  const lotNumber = job.get('lotNumber') || 'Not Set';
  const minWidth = isNotSet ? 'fit-content' : null;
  return (
    <div className="d-flex flex-row mt-4 pt-3">
      <div className="d-flex flex-column mr-5">
        <small className="text-muted">Builder</small>
        <span className="text-dark">{job.get('builderName')}</span>
      </div>
      <div className="d-flex flex-column mr-5">
        <small className="text-muted">Community</small>
        <Link
          to={{
            pathname: navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false),
          }}
        >
          {job.get('communityName')}
        </Link>
      </div>
      <div className="d-flex flex-column mr-5">
        <small className="text-muted">Job</small>
        <Link
          to={{
            pathname: navigate.from.TaskDetails.to.JobDetails({}, false),
          }}
        >
          {job.get('name')}
        </Link>
      </div>
      <div className="d-flex flex-column" style={{ minWidth }}>
        <small className="text-muted">LOT #</small>
        <span
          className={cn({
            'text-gray-200': isNotSet,
          })}
        >
          {lotNumber}
        </span>
      </div>
    </div>
  );
};

function getBackPath(status) {
  if (status === 'open') return navigate.from.TaskDetails.to.TaskOpen({}, false);
  //Else is completed
  return navigate.from.TaskDetails.to.TaskCompleted({}, false);
}

const TaskHistoryContainer = styled.div`
  display: flex;
  margin-right: 10px;
  margin-bottom: 15px;
`;
