import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, colors, ChipStatus, STATUS_MAP } from '../../index';
import moment from 'moment';

export function PrecedingStatusModal({ accept, cancel, task, precedingTasks, canEdit }) {
  const job = task.get('job');
  const hasPreceding = precedingTasks.size > 0;
  const lotNumber = job.get('lotNumber');
  const truncatedLotNumber = lotNumber?.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;

  return (
    <Modal show={true} onHide={cancel} dialogClassName="width-600">
      <Modal.Header closeButton>
        <Modal.Title>Preceding Tasks Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <span className="font-weight-bold">{task.get('name')}</span>
          {lotNumber ? (
            <span className="mb-4 text-gray-400">{`LOT # ${truncatedLotNumber}`}</span>
          ) : (
            <span className="font-size-14 mb-4">{job.get('name')}</span>
          )}
          <div className="mt-3 ml-n4 pl-4" style={{ overflowY: 'scroll', maxHeight: 330 }}>
            {precedingTasks.map(task => (
              <PrecedingTask task={task} key={task.get('id')} />
            ))}
            {!hasPreceding && (
              <p>
                This Task has no Preceding Tasks Set.
                {canEdit
                  ? ' Please, set them on Preceding Tasks Panel.'
                  : ' The Account Manager needs to set them up on Preceding Tasks Panel.'}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton onClick={() => accept()}>
          {hasPreceding || !canEdit ? 'OK' : 'Set Up Preceding Tasks'}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

const PrecedingTask = ({ task }) => {
  const {
    presentOnJob,
    name,
    status: taskStatus,
    startDate,
    expectedFinishDate,
    checkedIn,
    isChildTask,
    parentTaskName,
  } = task.toObject();
  const status = checkedIn ? 'checked-in' : taskStatus;
  const [dateLabel, date] =
    isChildTask || status === 'in-progress' ? ['Exp. Finish', expectedFinishDate] : ['Exp. Start', startDate];
  const subTaskLabel = parentTaskName ? `Sub-Task from ${parentTaskName}` : "This Sub-Task doesn't belong to any Task";

  return (
    <div className="d-flex flex-row mb-3">
      <span className="font-weight-bold mr-auto position-relative">
        <Warning show={!presentOnJob} />
        {name}
        {isChildTask ? (
          <div className="d-block mr-3 small text-secondary text-truncate" style={{ maxWidth: '300px' }}>
            {subTaskLabel}
          </div>
        ) : null}
      </span>
      <div className="d-flex flex-column" style={{ width: 160 }}>
        {presentOnJob ? <ChipStatus {...STATUS_MAP[status]} /> : <span className="mx-auto">Not in this job</span>}
        {presentOnJob && status !== 'completed' && (
          <span className="mx-auto font-size-12">
            {dateLabel} {moment(date, 'YYYY-MM-DD').format('MMMM D')}
          </span>
        )}
        <span className="mx-auto font-size-12 text-gray-400">{task.get('hourRange')}</span>
      </div>
    </div>
  );
};

const UnstyledWarning = ({ show, className }) => {
  if (!show) return null;
  return (
    <span className={className}>
      <FontAwesomeIcon icon="exclamation" />
    </span>
  );
};

const Warning = styled(UnstyledWarning)`
  background-color: ${colors.yellow100};
  display: inline;
  font-size: 8px;
  border-radius: 50%;
  position: relative;
  color: white;
  position: absolute;
  left: -20px;
  top: 6px;
  text-align: center;
  width: 13px;
  height: 12px;
`;
