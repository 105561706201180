import React from 'react';
import { countFilteringFields } from '../Filter.shared.utils';

export const Counter = ({ values, fields, emptyValues, isStatus = false }) => {
  const counter = React.useMemo(() => countFilteringFields(emptyValues, values, fields), [values]);

  if (isStatus) {
    if (counter === 0 || counter === fields.length) return 'All';
  } else if (counter === 0) return null;

  return <span className="badge rounded-pill bg-danger text-light">{counter}</span>;
};
