import React from 'react';
import { fromJS, Map } from 'immutable';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { isEqual } from 'lodash';
import { FilterTypeahead } from './FilterTypeahead';
import { Filter } from './Filter';

export const intakeEmptyFilter = fromJS({
  accountAssignee: [],
});
export const outtakeEmptyFilter = Map({
  account: intakeEmptyFilter,
  users: intakeEmptyFilter,
});

const unassignedOption = fromJS({ name: 'Unassigned', _id: 'unassigned', subAccountId: 'unassigned' });
const insideFilter = ['icon-filter', 'intake-filter', 'Account-Assignee', 'Assignee', 'clear-filters'];

export const OuttakeFilter = ({ tab, filter, ...props }) => {
  const field = tab === 'account' ? 'account' : 'users';
  return <CommonFilter filter={filter.get(field)} {...props} />;
};

export const IntakeFilter = props => {
  return <CommonFilter {...props} />;
};

const CommonFilter = props => {
  const { controller, filter, title, accountAssignee, fieldLabel = 'Assignee' } = props;
  const onApplyFilters = controller.applyFilters;
  const formContext = useForm({ defaultValues: filter?.toJS() });
  const { handleSubmit, reset, control } = formContext;

  const isFilterSet = React.useMemo(() => {
    return filter !== null && !isEqual(filter?.toJS(), intakeEmptyFilter.toJS());
  }, [filter]);

  const onChange = () => handleSubmit(onApplyFilters)();
  const clearFilter = () => {
    const resetValues = fieldLabel === 'Assignee' ? intakeEmptyFilter : outtakeEmptyFilter;
    reset(resetValues.toJS());
    onApplyFilters(intakeEmptyFilter.toJS());
  };

  const counter = Object.keys(filter.toJS()).reduce((acc, field) => filter.get(field).size + acc, 0);
  return (
    <Filter formContext={formContext} isFilterSet={isFilterSet} insideFilter={insideFilter} {...props}>
      <Form onSubmit={handleSubmit(onApplyFilters)}>
        <div className="d-flex settings-container flex-column justify-content-start">
          <div className="d-flex justify-content-between">
            <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
              {title}
            </h5>
            {isFilterSet ? (
              <span className="btn btn-link p-0" id="clear-filters" onClick={clearFilter}>
                Clear all filters
              </span>
            ) : null}
          </div>
          <FilterTypeahead
            formContext={formContext}
            name="accountAssignee"
            options={accountAssignee?.unshift(unassignedOption)}
            text={fieldLabel}
            onChange={onChange}
            control={control}
            counter={counter}
          />
        </div>
      </Form>
    </Filter>
  );
};
