import { markAsSync, markAsSideEffect } from '../../index';
import { fromJS, List } from 'immutable';
import { setCheckedInStatus, formatISO, mongoToISO, getUserDomain } from '../../utils';
import { getQueryParam } from '../getQueryParams';
import { TaskUpdateRequestModal } from '../../ToDo/TaskUpdateRequestModal'; //Move to common

const dashKey = task => `${task.getIn(['job', 'id'])}_${task.get('id')}`;

// Update Requests

export function readUpdateRequests() {
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam({ request: true }, filter);
  return this.dashboardService
    .readTasks({}, { query })
    .then(fromJS)
    .then(tasks => tasks.map(task => task.set('key', dashKey(task))))
    .then(tasksUpdateRequest => tasksUpdateRequest.map(setCheckedInStatus))
    .then(tasksUpdateRequest => state => {
      this.invalidateCounters({ requestCount: tasksUpdateRequest.size });

      return state.set('tasksUpdateRequest', tasksUpdateRequest);
    })
    .catch(() => state => state.set('tasksUpdateRequest', List()));
}

markAsSync(updateStatus);
export function updateStatus(state, { task, status }) {
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const tasks = state.get('tasksUpdateRequest');
  const taskIndex = tasks.indexOf(task);
  this.dashboardService.updateTask({ status }, { params: { jobId, taskId } }).catch(() => {
    this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
    this.controller.dispatch([state => state.setIn(['tasksUpdateRequest', taskIndex], task)]);
  });
  return state.setIn(['tasksUpdateRequest', taskIndex, 'status'], status);
}

markAsSync(cancelExpFinishDateRequest);
export function cancelExpFinishDateRequest(state, { task, isTrade, bulkContext }) {
  const jobId = task.getIn(['job', 'id']);
  const tasks = state.get('tasksUpdateRequest');
  const taskId = task.get('id');
  const index = tasks.indexOf(task);

  this.dashboardService
    .rejectFinishDateRequest({}, { params: { jobId, taskId } })
    .then(() => {
      this.addAlert(`Update request declined. ${isTrade ? 'Installer' : 'Trade'} user will be notified.`);
      this.appController.readTaskCounters();
      if (bulkContext) bulkContext.controller.toggleCheckRequest(task);
    })
    .catch(() => {
      this.controller.dispatch([state => state.set('tasksUpdateRequest', tasks)]);
      this.addAlert(
        `There was a problem declining this update request from the ${
          isTrade ? 'Installer' : 'Trade'
        }. Please try again.`,
        'danger'
      );
    });
  return state.deleteIn(['tasksUpdateRequest', index]);
}

markAsSync(acceptStartDateUpdateRequest);
export function acceptStartDateUpdateRequest(state, { task, isTrade, tasksHeld, proposedDate, bulkContext, reasons }) {
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const tasks = state.get('tasksUpdateRequest');
  const date = task.getIn(['changeRequest', 'proposedStartDate']);
  const newStartDate = proposedDate ? formatISO(proposedDate) : mongoToISO(date);
  const index = tasks.indexOf(task);
  const successMsg = isTrade
    ? 'Update Request from Installer successfully sent to Builder.'
    : 'Update request successfully accepted. Trade user will be notified.';

  const query = {};
  if (tasksHeld?.size) query.tasksToDeletePredecessors = tasksHeld.toJS();

  const acceptUR = isTrade
    ? this.dashboardService.acceptFinishDateRequest({ reasons }, { params: { jobId, taskId } })
    : this.dashboardService.acceptStartDateRequest({ newStartDate, reasons }, { params: { jobId, taskId }, query });

  acceptUR
    .then(() => {
      this.addAlert(successMsg);
      this.appController.readTaskCounters();

      if (bulkContext) bulkContext.controller.toggleCheckRequest(task);
    })
    .catch(() => {
      this.addAlert(
        `There was a problem ${isTrade ? 'confirming' : 'accepting'} this update request from the ${
          isTrade ? 'Installer' : 'Trade'
        }. Please try again.`,
        'danger'
      );
      this.controller.dispatch([state => state.set('tasksUpdateRequest', tasks)]);
    });
  return state.deleteIn(['tasksUpdateRequest', index]);
}

markAsSync(acceptExpFinishDateUpdateRequest);
export function acceptExpFinishDateUpdateRequest(
  state,
  { task, isTrade, tasksHeld, proposedDate, bulkContext, reasons }
) {
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const tasks = state.get('tasksUpdateRequest');
  const date = task.getIn(['changeRequest', 'proposedFinishDate']);
  const newEndDate = proposedDate ? formatISO(proposedDate) : mongoToISO(date);
  const index = tasks.indexOf(task);
  const successMsg = isTrade
    ? 'Update Request from Installer successfully sent to Builder.'
    : 'Update request successfully accepted. Trade user will be notified.';

  const query = {};
  if (tasksHeld?.size) query.tasksToDeletePredecessors = tasksHeld.toJS();

  this.dashboardService
    .acceptFinishDateRequest({ newEndDate, reasons }, { params: { jobId, taskId }, query })
    .then(() => {
      this.addAlert(successMsg);
      this.appController.readTaskCounters();
      if (bulkContext) bulkContext.controller.toggleCheckRequest(task);
    })
    .catch(() => {
      this.addAlert(
        `There was a problem accepting this update request from the ${
          isTrade ? 'Installer' : 'Trade'
        }. Please try again.`,
        'danger'
      );
      this.controller.dispatch([state => state.set('tasksUpdateRequest', tasks)]);
    });

  return state.deleteIn(['tasksUpdateRequest', index]);
}

markAsSideEffect(markTaskAsSeen, markChangeRequestAsSeen);

export function markTaskAsSeen({ jobId, taskId }) {
  return this.dashboardService.markTaskAsSeen({}, { params: { jobId, taskId } }, false);
}

export function markChangeRequestAsSeen({ jobId, taskId }) {
  return this.dashboardService.markAsSeenChangeRequest({}, { params: { jobId, taskId } });
}

// OVERDUE

export function readTasksOverdue() {
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam({ overdue: 'all' }, filter);
  return this.dashboardService
    .readTasks({}, { query })
    .then(fromJS)
    .then(tasks => tasks.map(task => task.set('key', dashKey(task))))
    .then(tasksOverdue => tasksOverdue.map(setCheckedInStatus))
    .then(tasksOverdue => state => {
      this.invalidateCounters({ overdueCount: tasksOverdue.size });
      return state.set('tasksOverdue', tasksOverdue);
    })
    .catch(() => state => state.set('tasksOverdue', List()));
}

markAsSync(updateStatusOverdue);
export function updateStatusOverdue(state, { task, status, bulkContext }) {
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const tasks = state.get('tasksOverdue');
  const taskIndex = tasks.indexOf(task);
  this.dashboardService
    .updateTask({ status }, { params: { jobId, taskId } })
    .then(responseTask => {
      if (!responseTask.overdue) {
        this.controller.dispatch([state => state.deleteIn(['tasksOverdue', taskIndex])]);
        this.appController.readTaskCounters();
        if (bulkContext) bulkContext.controller.toggleCheckOverdue(task);
      } else {
        this.controller.dispatch([state => state.setIn(['tasksOverdue', taskIndex], fromJS(responseTask))]);
      }
    })
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(['tasksOverdue', taskIndex], task)]);
    });

  return state.setIn(['tasksOverdue', taskIndex, 'status'], status);
}

markAsSideEffect(acceptDateOverdueRequest);
export function acceptDateOverdueRequest(jobId, task, proposedDate, tasksHeld, bulkContext, reasons) {
  const taskId = task.get('id');
  const isEndDate = task.get('overdue') === 'finish';
  const newDate = isEndDate ? proposedDate.newEndDate : proposedDate.newStartDate;
  const date = isEndDate ? { endDate: newDate } : { startDate: newDate };
  const index = this.state.get('tasksOverdue').indexOf(task);

  const query = {};
  if (tasksHeld?.size) query.tasksToDeletePredecessors = tasksHeld.toJS();
  if (reasons) query.reasons = reasons;

  this.dashboardService
    .updateTask(date, { params: { jobId, taskId }, query })
    .then(responseTask => {
      if (responseTask.overdue) {
        this.controller.dispatch([
          state => state.setIn(['tasksOverdue', index, isEndDate ? 'expectedFinishDate' : 'startDate'], newDate),
        ]);
      } else {
        this.controller.dispatch([state => state.deleteIn(['tasksOverdue', index])]);
        this.appController.readTaskCounters();
        if (bulkContext) bulkContext.controller.toggleCheckRequest(task);
      }
    })
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
    });
}

export function sendStartDateRequest(startDate, jobId, taskId, task, reasons) {
  const newStartDate = formatISO(startDate);
  const tasks = this.state.get('tasksOverdue');
  const taskIndex = tasks.indexOf(task);
  const startDateConfirmation = !!task.get('startDateConfirmationRequest');

  return this.dashboardService
    .sendStartDateRequest({ newStartDate, startDateConfirmation, reasons }, { params: { jobId, taskId } })
    .then(response => state => {
      this.alert.success({ message: 'Update request successfully sent.' });
      const { changeRequestOnReviewByBuilder } = response;
      this.appController.readTaskCounters();
      return state
        .setIn(['task', 'changeRequestOnReviewByBuilder'], fromJS(changeRequestOnReviewByBuilder))
        .setIn(['tasksOverdue', taskIndex], fromJS(response));
    })
    .catch(err => {
      this.addAlert('There was a problem sending this update request to the Builder. Please try again', 'danger');
      throw err;
    });
}

markAsSideEffect(confirmDateChangeRequest);
export function confirmDateChangeRequest(task, newStartDate, reasons) {
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const taskIndex = this.state.get('tasksOverdue').indexOf(task);
  const startDateConfirmation = !!task.get('startDateConfirmationRequest');

  this.dashboardService
    .sendStartDateRequest({ newStartDate, startDateConfirmation, reasons }, { params: { jobId, taskId } })
    .then(response => {
      const { changeRequestOnReviewByBuilder } = response;
      this.controller.dispatch([
        state =>
          state
            .setIn(['task', 'changeRequestOnReviewByBuilder'], fromJS(changeRequestOnReviewByBuilder))
            .setIn(['tasksOverdue', taskIndex], fromJS(response)),
      ]);
      this.addAlert('Start Date Confirmation Update Request successfully sent.');
      this.appController.readTaskCounters();
    })
    .catch(err => {
      this.addAlert('There was a problem sending this update request to the Builder. Please try again.', 'danger');
      throw err;
    });
}

export function sendFinishDateRequest(finishDate, jobId, taskId, task, reasons) {
  const newEndDate = formatISO(finishDate);
  const tasks = this.state.get('tasksOverdue');
  const taskIndex = tasks.indexOf(task);
  return this.dashboardService
    .requestNewEndDate({ newEndDate, reasons }, { params: { jobId, taskId } })
    .then(response => state => {
      this.alert.success({ message: 'Update request successfully sent.' });
      const { changeRequestOnReviewByBuilder } = response;
      this.appController.readTaskCounters();
      return state
        .setIn(['task', 'changeRequestOnReviewByBuilder'], fromJS(changeRequestOnReviewByBuilder))
        .setIn(['tasksOverdue', taskIndex], setCheckedInStatus(fromJS(response)));
    })
    .catch(err => {
      this.addAlert('There was a problem sending this update request to the Builder. Please try again.', 'danger');
      throw err;
    });
}

markAsSideEffect(sendDateUpdateRequest);
export async function sendDateUpdateRequest(jobId, task) {
  const { controller } = this;
  const isStartDateConfirmationRequest = !!task.get('startDateConfirmationRequest');
  const canProposeFinish = !isStartDateConfirmationRequest;
  const { form, isAccept } = await this.modal.open(TaskUpdateRequestModal, { task, canProposeFinish });
  const taskId = task.get('id');
  const reasonsArray = form?.reasons ? ['weather'] : [];

  if (!isAccept) return;

  try {
    const { startDate, finishDate } = form;

    if (isStartDateConfirmationRequest) {
      return controller.confirmDateChangeRequest(task, formatISO(startDate), reasonsArray);
    }

    if (startDate) {
      return controller.sendStartDateRequest(startDate, jobId, taskId, task, reasonsArray);
    }

    if (finishDate) {
      return controller.sendFinishDateRequest(finishDate, jobId, taskId, task, reasonsArray);
    }
  } catch (error) {
    this.alert.error({ message: 'There was a problem sending this update request. Please try again.' });
  }
}

markAsSideEffect(readJob);
export async function readJob(jobId) {
  const [job, tasks] = await Promise.all([
    this.dashboardService.readJob({}, { params: { jobId } }).then(fromJS),
    this.dashboardService.readJobTasks({}, { params: { jobId }, query: { includeStageTasks: true } }).then(fromJS),
  ]).catch(error => this.alert.error({ message: 'There was a problem fetching the job data. Please try again.' }));
  return { job, tasks };
}

markAsSideEffect(readJobTrade);
export async function readJobTrade(jobId) {
  const [job, tasks] = await Promise.all([
    this.dashboardService.readJobDetail({}, { params: { jobId } }).then(fromJS),
    this.dashboardService.readJobTasks({}, { params: { jobId } }).then(fromJS),
  ]).catch(error => this.alert.error({ message: 'There was a problem fetching the job data. Please try again.' }));
  return { job, tasks };
}

markAsSideEffect(openChangeDateModalOverdue);
export function openChangeDateModalOverdue({ task, canSendUR, bulkContext }) {
  const { user } = this.appState.toObject();
  const { isBuilder } = getUserDomain(user);
  if (isBuilder) {
    return this.controller.openTasksAffectedModal(task, { isOverdue: true, bulkContext });
  }

  if (canSendUR) {
    const jobId = task.getIn(['job', 'id']);
    this.controller.sendDateUpdateRequest(jobId, task);
  } else {
    this.addAlert("You don't have permission to manage Update Requests. Please contact your supervisor.", 'danger');
  }
}
