import { fromJS, Map, List } from 'immutable';
import { markAsSideEffect, markAsSync, TaskBulkAssignmentModal } from '@tradetrax/web-common';
import { MINIMUM_BATCH_SIZE, SCHEDULER_TAB, SUPER_TAB, USER_TYPE } from './IntakeController';
import { subsService } from 'services';

export function readCommunity(communityId) {
  return subsService
    .readCommunity({}, { params: { communityId } })
    .then(community => state => state.set('community', fromJS(community)));
}

markAsSync(setTab);
export function setTab(state, tab) {
  const isFiltering = this.filterState.get('isFiltering');
  const updatedState = state.set('tab', tab);
  if (isFiltering) {
    setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
    return updatedState.update('allTasks', allTasks =>
      allTasks
        .set('totalCount', 10)
        .set('maxCount', 10)
        .set('tasks', List())
    );
  }
  return updatedState;
}

markAsSync(setFilter);
export function setFilter(state, filter) {
  return state.set('filter', filter);
}

export function readCommunityTasks({ communityId, startIndex, stopIndex }) {
  const tab = this.state.get('tab');
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam({ start_index: startIndex, stop_index: stopIndex }, tab, filter);
  return subsService.readCommunityTasks({}, { params: { communityId }, query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    return state =>
      state.update('allTasks', allTasks =>
        allTasks
          .set('totalCount', totalCount)
          .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
          .update('tasks', tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()))
      );
  });
}

markAsSync(assignUserToTask);
export function assignUserToTask(state, { assignee, rowData, tab }) {
  const { task, job } = rowData.toObject();
  const filterIntake = this.filterState.get('values');
  const jobId = job.get('id');
  const taskId = task.get('id');
  const index = state.getIn(['allTasks', 'tasks']).indexOf(rowData);
  const [isSuper, isScheduler] = [tab === SUPER_TAB, tab === SCHEDULER_TAB];
  const userType = USER_TYPE[tab];

  const assigneeStatus = assignee ? assignee.get('status') : 'active';
  const assigneeId = assignee ? assignee.get('_id') : null;
  const assigneeName = assignee ? `${assignee.get('firstName')} ${assignee.get('lastName')}` : null;
  const payload = {};
  if (isSuper) {
    payload.userSuperId = assigneeId;
  } else if (isScheduler) {
    payload.userSchedulerId = assigneeId;
  }

  subsService.updateTask(payload, { params: { jobId, taskId } }).catch(() => {
    this.controller.dispatch([state => state.setIn(['allTasks', 'tasks', index], rowData)]);
    const userType = isSuper ? 'Super' : 'Scheduler';
    this.addAlert(
      `There was a problem assigning a 
      ${userType} to this Task. Please try again.`,
      'danger'
    );
  });

  const selectedTasksIds = state.getIn(['selectedTasks', tab, 'taskIds']);
  const assigneeIndex = selectedTasksIds.findIndex(taskId => taskId === rowData.get('id'));
  return state
    .updateIn(['allTasks', 'tasks', index, 'task'], task =>
      task.update(userType, user => {
        const assignee = user || Map();
        return assignee
          .set('status', assigneeStatus)
          .set('name', assigneeName)
          .set('_id', assigneeId);
      })
    )
    .update('allTasks', allTasks => {
      let totalCount = allTasks.get('totalCount');
      return allTasks
        .update('tasks', tasks => {
          const filterAssignees = filterIntake.get('assigneeUserIds');
          if (filterAssignees.size === 0) return tasks;
          const index = tasks.findIndex(a => a.get('id') === rowData.get('id'));
          const { userSuper, userScheduler } = tasks.getIn([index, 'task']).toObject();

          const taskMatchingFilter = filterAssignees.filter(assigneeId => {
            if (isSuper) return userSuper?.get('_id') === assigneeId;
            return userScheduler?.get('_id') === assigneeId;
          });

          if (taskMatchingFilter.size) return tasks;
          totalCount--;
          return tasks.splice(index, 1);
        })
        .set('totalCount', totalCount);
    })
    .updateIn(['selectedTasks', tab, 'currentAssignees', assigneeIndex], currentUserId => {
      if (assigneeIndex >= 0) return assigneeId;
      else return currentUserId;
    });
}

markAsSync(toggleSelectAll);
export function toggleSelectAll(state, checked) {
  const tab = state.get('tab');
  const updatedState = state.updateIn(['selectedTasks', tab], selectedTasks =>
    selectedTasks
      .set('currentAssignees', List())
      .set('selectAll', checked)
      .set('taskIds', List())
  );
  if (checked) return updatedState;

  return updatedState.updateIn(['selectedTasks', tab], selectedTasks => {
    return selectedTasks.set('notIncludeTaskIds', List());
  });
}

markAsSync(onSelectCheckbox);
export function onSelectCheckbox(state, { checked, task, tab }) {
  const taskId = task.get('id');
  const taskAssigneeId = task.getIn(['task', USER_TYPE[tab], '_id']) || null;
  const isSelectAll = state.getIn(['selectedTasks', tab, 'selectAll']);

  if (isSelectAll) return addRemoveFromExcluded(state, tab, taskId, checked);

  if (checked)
    return state.updateIn(['selectedTasks', tab], selectedtasks =>
      selectedtasks
        .update('taskIds', ids => ids.push(taskId))
        .update('currentAssignees', currentAssignees => currentAssignees.push(taskAssigneeId))
    );
  else {
    const index = state.getIn(['selectedTasks', tab, 'taskIds']).indexOf(taskId);
    if (index >= 0) {
      return state.updateIn(['selectedTasks', tab], selectedTasks =>
        selectedTasks
          .update('taskIds', ids => ids.remove(index))
          .update('currentAssignees', currentAssignees => {
            const index = currentAssignees.indexOf(taskAssigneeId);
            return currentAssignees.splice(index, 1);
          })
      );
    }
  }
}

function addRemoveFromExcluded(state, tab, taskId, checked) {
  if (checked) {
    const index = state.getIn(['selectedTasks', tab, 'notIncludeTaskIds']).indexOf(taskId);
    return state.updateIn(['selectedTasks', tab, 'notIncludeTaskIds'], taskIds => taskIds.splice(index, 1));
  } else return state.updateIn(['selectedTasks', tab, 'notIncludeTaskIds'], taskIds => taskIds.push(taskId));
}

markAsSideEffect(openAssignTasksModal);
export async function openAssignTasksModal() {
  const { tab, community } = this.state.toObject();
  const selectedTasks = this.state.getIn(['selectedTasks', tab]);
  const taskIds = selectedTasks.get('taskIds').toJS();
  const isSelectAll = selectedTasks.get('selectAll');

  const selectedAssignees = selectedTasks.get('currentAssignees');
  const isAnyNotAssigned = selectedAssignees.includes(null);
  const taskAssignees = [...new Set(selectedAssignees)].filter(item => item != null);
  const userType = USER_TYPE[tab].replace('user', '');
  const assigneeOptions = this.account.get('regularUsersActive');
  const { isAccept, assignee } = await this.modal.open(TaskBulkAssignmentModal, {
    context: this,
    community,
    isAnyNotAssigned,
    taskAssignees,
    tab,
    assigneeOptions,
    isSelectAll,
  });
  if (!isAccept) return;

  if (isSelectAll) return assignTasksByQuery.call(this, assignee);

  const tasks = this.state.getIn(['allTasks', 'tasks']);
  this.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks
            .set('taskIds', List())
            .set('selectAll', false)
            .set('notIncludeTaskIds', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(task => {
            if (taskIds.includes(task.get('id'))) {
              return task.updateIn(['task', USER_TYPE[tab]], currentUser => {
                const [assigneeUserId, assigneeUserName] = assignee
                  ? [assignee.get('_id'), `${assignee.get('firstName')} ${assignee.get('lastName')}`]
                  : [null, null];
                if (!assigneeUserId) return null;
                const updatedUser = currentUser ? currentUser : Map();
                return updatedUser
                  .set('_id', assigneeUserId)
                  .set('name', assigneeUserName)
                  .set('status', 'active');
              });
            } else return task;
          })
        ),
  ]);

  const isSuperAssigned = tab === 'super';
  const isSchedulerAssigned = tab === 'scheduler';
  const userId = assignee ? assignee.get('_id') : null;
  const assignTasksData = { taskIds };
  if (isSchedulerAssigned) assignTasksData.userSchedulerId = userId;
  if (isSuperAssigned) assignTasksData.userSuperId = userId;

  return subsService
    .assignUsersToTasks(assignTasksData, { params: { communityId: community.get('_id') } })
    .then(() => {
      const assignUnassign = assignee ? 'assigned to' : 'removed from';
      this.alert.success({ message: `${userType} successfully ${assignUnassign} these Tasks.` });
    })
    .catch(() => {
      const assignUnassign = assignee ? 'assigning' : 'removing';
      this.alert.error({
        message: `There was a problem ${assignUnassign} a ${userType} to these Tasks. Please try again.`,
      });
      this.controller.dispatch([
        state =>
          state
            .setIn(['allTasks', 'tasks'], tasks)
            .updateIn(['selectedTasks', tab], selectedTasks =>
              selectedTasks.set('taskIds', fromJS(taskIds)).set('currentAssignees', selectedAssignees)
            ),
      ]);
    });
}

markAsSideEffect(assignTasksByQuery);
export function assignTasksByQuery(assignee) {
  const { tab } = this.state.toObject();
  const userId = assignee ? assignee.get('_id') : null;
  const filterIntake = this.filterState.get('values');
  const communityId = this.state.getIn(['community', '_id']);
  const notIncludeTaskIds = this.state.getIn(['selectedTasks', tab, 'notIncludeTaskIds']);
  const prevTasks = this.state.getIn(['allTasks', 'tasks']);
  const body = {};
  const query = {
    assigneeUserType: tab,
  };
  if (tab === 'super') body.userSuperId = userId;
  if (tab === 'scheduler') body.userSchedulerId = userId;
  if (notIncludeTaskIds.size) query.notIncludeTaskIds = notIncludeTaskIds.toJS();
  if (filterIntake.get('assigneeUserIds').size) {
    query.assigneeUserIds = filterIntake.get('assigneeUserIds').toJS();
  }

  this.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks
            .set('taskIds', List())
            .set('selectAll', false)
            .set('notIncludeTaskIds', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(task => {
            if (notIncludeTaskIds.includes(task.get('id'))) return task;
            else {
              return task.updateIn(['task', USER_TYPE[tab]], currentUser => {
                const [assigneeUserId, assigneeUserName] = assignee
                  ? [assignee.get('_id'), `${assignee.get('firstName')} ${assignee.get('lastName')}`]
                  : [null, null];
                if (!assigneeUserId) return null;
                const updatedUser = currentUser ? currentUser : Map();
                return updatedUser
                  .set('_id', assigneeUserId)
                  .set('name', assigneeUserName)
                  .set('status', 'active');
              });
            }
          })
        ),
  ]);

  const assignUnassign = userId ? 'assigned to' : 'removed from';
  const userType = USER_TYPE[tab].replace('user', '');
  return subsService
    .assignUsersToTasksByQuery(body, { params: { communityId }, query })
    .then(() => {
      this.alert.success({ message: `${userType} successfully ${assignUnassign} these Tasks.` });
    })
    .catch(error => {
      this.alert.error({
        message: `There was a problem ${assignUnassign} a ${userType} to these Tasks. Please try again.`,
      });
      this.controller.dispatch([state => state.setIn(['allTasks', 'tasks'])], prevTasks);
    });
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.set('allTasks', fromJS({ tasks: [], totalCount: 10, maxCount: 10 }));
}

// export function getQueryParam(query = {}, filter) {
export function getQueryParam(query = {}, tab, filter) {
  if (!filter) return query;

  if (filter.assigneeUserIds?.length) {
    query.assigneeUserType = tab;
    query.assigneeUserIds = filter.assigneeUserIds;
  }

  return query;
}
