import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrecedingTask } from './PrecedingTask';
import { AddPrecedingTask } from './AddPrecedingTask';

// CTR = Custom Task Readiness
export const CTRTask = ({ context, ctrTask, expanded = false, canEdit }) => {
  const preceding = ctrTask.get('precedingTasks');
  const { controller } = context;
  const onClick = () => controller.toggle(ctrTask.get('gtlTaskId'));

  return (
    <li className={cn('item', { expanded: expanded })}>
      <div className={cn('d-flex flex-row', { 'mb-3': expanded })}>
        {canEdit && (
          <button className="btn btn-link p-0 text-secondary" onClick={() => controller.deleteCTRTask(ctrTask)}>
            <FontAwesomeIcon icon="trash" />
          </button>
        )}
        <button
          className={cn('mr-auto align-self-center btn btn-link font-weight-bold text-dark text-decoration-none', {
            'ml-3': canEdit,
          })}
          onClick={onClick}
        >
          {ctrTask.get('name')}
        </button>
        <button className="btn btn-link p-0 text-secondary" onClick={onClick}>
          <FontAwesomeIcon icon={`chevron-${expanded ? 'up' : 'down'}`} className="mt-2" />
        </button>
      </div>
      {expanded && (
        <>
          {canEdit && <AddPrecedingTask context={context} ctrTask={ctrTask} />}
          {!canEdit && <span className="ml-2 pl-1 font-size-14">This Task Type has no Preceding Tasks set.</span>}
          {preceding.map(task => (
            <PrecedingTask task={task} key={task.get('name')} context={context} ctrTask={ctrTask} canEdit={canEdit} />
          ))}
        </>
      )}
    </li>
  );
};
