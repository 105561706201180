import { markAsSync } from '../../index';
import { getQueryParam } from '../getQueryParams';
import { getUserDomain } from '../../utils';
import { FILTER_NAME } from '../../Filters/Implementation/filter.name';

const BATCH_SIZE = 10;

markAsSync(conciliateCounters);
export function conciliateCounters(state, taskCounters) {
  const { toDoStartCount, toDoEndCount, toDoUpdateCount, startDateConfirmationRequestCount } = taskCounters.toObject();

  return state.updateIn(['toDo'], todo =>
    todo
      .set('startToday', toDoStartCount)
      .set('finishToday', toDoEndCount)
      .set('updateToday', toDoUpdateCount)
      .set('confirmationRequests', startDateConfirmationRequestCount)
  );
}

markAsSync(loadStartTodayTasks);
export function loadStartTodayTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const startDate = this.filtersState.getIn([FILTER_NAME.TO_DO, 'sessionValues', 'selectedDate']) || 'today';
  const status = ['not-started', 'not-ready-to-start'];
  const filter = this.filtersState.getIn([FILTER_NAME.TO_DO, 'values']).toJS();
  const query = getQueryParam(
    { sort: 'status', status, start_index, stop_index, startDate, ...this.todoQuery },
    filter
  );

  // TODO: we should not read the whole todo list just to get the counters!!!
  this.dashboardService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;

    this.invalidateCounters({ toDoStartCount: totalCount });
    this.controller.dispatch([state => state.setIn(['toDo', 'startToday'], totalCount)]);
  });

  return start_index === 0 ? state.setIn(['toDo', 'startToday'], 0) : state;
}

markAsSync(loadFinishTodayTasks);
export function loadFinishTodayTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const endDate = this.filtersState.getIn([FILTER_NAME.TO_DO, 'sessionValues', 'selectedDate']) || 'today';
  const status = ['not-started', 'not-ready-to-start', 'in-progress'];
  const filter = this.filtersState.getIn([FILTER_NAME.TO_DO, 'values']).toJS();
  const query = getQueryParam({ sort: 'status', status, start_index, stop_index, endDate, ...this.todoQuery }, filter);
  this.dashboardService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;

    this.invalidateCounters({ toDoEndCount: totalCount });

    this.controller.dispatch([state => state.setIn(['toDo', 'finishToday'], totalCount)]);
  });
  return start_index === 0 ? state.setIn(['toDo', 'finishToday'], 0) : state;
}

// Builders only
markAsSync(loadUpdateTodayTasks);
export function loadUpdateTodayTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const startDate = this.filtersState.getIn([FILTER_NAME.TO_DO, 'sessionValues', 'selectedDate']) || 'today';
  const endDate = startDate;
  const status = ['not-started', 'not-ready-to-start', 'in-progress'];
  const filter = this.filtersState.getIn([FILTER_NAME.TO_DO, 'values']).toJS();
  const query = getQueryParam(
    {
      sort: 'status',
      isAssignedToSelfAccount: false,
      status,
      stop_index,
      start_index,
      startDate,
      endDate,
    },
    filter
  );

  this.dashboardService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    this.invalidateCounters({ toDoUpdateCount: totalCount });
    this.controller.dispatch([state => state.setIn(['toDo', 'updateToday'], totalCount)]);
  });

  return start_index === 0 ? state.setIn(['toDo', 'updateToday'], 0) : state;
}

// Trades only
markAsSync(loadWithConfirmationRequestTasks);
export function loadWithConfirmationRequestTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const { isTrade } = getUserDomain(this.appState.get('user'));
  const filter = this.filtersState.getIn([FILTER_NAME.TO_DO, 'values']).toJS();
  const query = getQueryParam(
    {
      sort: 'status',
      start_index,
      stop_index,
      ...this.todoQuery,
    },
    filter
  );
  if (isTrade) query.startDateConfirmationRequest = true;

  this.dashboardService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    this.invalidateCounters({ startDateConfirmationRequestCount: totalCount });
    this.controller.dispatch([state => state.setIn(['toDo', 'confirmationRequests'], totalCount)]);
  });

  return start_index === 0 ? state.setIn(['toDo', 'confirmationRequests'], 0) : state;
}
