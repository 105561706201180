import React from 'react';
import cn from 'classnames';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller as FormController } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FilterTypeahead = ({ text, options: inputOptions, control, name, onChange, updateCounter, counter }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const options = React.useMemo(() => inputOptions.toJS(), [inputOptions]);
  const hasValue = !!counter;
  return (
    <>
      <div className="d-flex justify-content-between py-4 border-top">
        <span className="font-weight-bold">{text}</span>
        <span>
          {hasValue ? (
            <span className="badge rounded-pill bg-danger text-light">{counter > 99 ? '+99' : counter}</span>
          ) : (
            'All'
          )}
          <FontAwesomeIcon
            className="mx-3 cursor-pointer"
            icon={`chevron-${isCollapsed ? 'down' : 'up'}`}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        </span>
      </div>
      <div className={cn({ invisible: isCollapsed, 'h-0': isCollapsed })}>
        <FormController
          name={name}
          control={control}
          render={({ onChange: controllerOnChange, value }) => (
            <Typeahead
              className="mb-4 d-flex align-items-top"
              clearButton
              id={`${text.replace(' ', '-')}`}
              labelKey={option => option.name ?? `${option.firstName} ${option.lastName}`}
              multiple
              onChange={e => {
                controllerOnChange(e);
                onChange();
                updateCounter && updateCounter(name, e.length);
              }}
              options={options}
              placeholder={`Search ${text}`}
              paginationText="See more results..."
              value={value}
              selected={value}
            >
              {!hasValue && <FontAwesomeIcon icon="magnifying-glass" className="mx-1" />}
            </Typeahead>
          )}
        />
      </div>
    </>
  );
};
