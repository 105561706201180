import React from 'react';
import { fromJS, Map, List } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/AppContext';
import * as actions from './IntakeActions';
import { intakeEmptyFilter } from '@tradetrax/web-common/lib/Filters/IntakeFilter';
import { subsService as intakeService } from 'services';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

export const ALL_TASKS = 'all-tasks';
export const SCHEDULER_TAB = 'scheduler';
export const SUPER_TAB = 'super';
export const MINIMUM_BATCH_SIZE = 25;

export const USER_TYPE = {
  [SUPER_TAB]: 'userSuper',
  [SCHEDULER_TAB]: 'userScheduler',
};

const emptySelectedItems = fromJS({
  selectAll: false,
  notIncludeTaskIds: [],
  taskIds: [],
  currentAssignees: [],
});

const emptyState = fromJS({
  tab: SUPER_TAB,
  tasksBy: ALL_TASKS,
  community: {
    _id: 'aaaaaabbbbbbccccccdddddd',
    name: '',
  },
  allTasks: Map({
    tasks: List(),
    totalCount: 10,
    maxCount: 10,
  }),
  selectedTasks: {
    super: emptySelectedItems,
    scheduler: emptySelectedItems,
  },
  isFilterActive: false,
  filterIntake: intakeEmptyFilter,
});

export function IntakeController(communityId) {
  const appContext = useAppContext();
  const loaderRef = React.useRef();
  const filterContext = useFilterContext(FILTER_NAME.INTAKE);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    loaderRef,
    intakeService,
    filterState,
  });
  const filterIntake = filterState.get('values');

  React.useEffect(() => {
    controller.readCommunity(communityId);
  }, [communityId, controller]);

  React.useEffect(() => {
    controller.invalidateFilter();
    // const { _lastRenderedStartIndex: startIndex, _lastRenderedStopIndex: stopIndex } = loaderRef?.current;
    // if (startIndex !== undefined || stopIndex !== undefined) {
    //   setTimeout(() => loaderRef.current?.resetLoadMoreRowsCache(true), 1);
    //   controller.readCommunityTasks({ communityId, startIndex, stopIndex, tab });
    // }
  }, [communityId, controller, filterIntake]);

  return { state, controller, loaderRef, filterContext };
}
